import React from "react";
import YouTube from "../../components/mdxComponents/YouTube";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import H from "../../components/Headline";


const breadCrumbLevels = {
  Home: "/",
  "Analytics Consulting": "/nl/analytics-consulting",
  "Tag Management": "/nl/tagbeheer"
};

// hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/tag-management"
  },
  {
    hreflang: "de",
    href: "/de/tag-management"
  },
  {
    hreflang: "se",
    href: "/se/tagghantering"
  },
  {
    hreflang: "da",
    href: "/da/tag-styring"
  },
  {
    hreflang: "no",
    href: "/no/tagg-administrasjon"
  },
  {
    hreflang: "nl",
    href: "/nl/tagbeheer"
  },
  {
    hreflang: "x-default",
    href: "/en/tag-management"
  }
];

// Schema
const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Wat is een tagbeheersysteem?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Een tagbeheersysteem is een JavaScript-bibliotheek om analytische en marketingtags te implementeren en configureren. HTML-tags, JavaScript-snippets of trackingpixels voegen functies toe aan uw website of app en kunnen met slechts een paar klikken worden geïnstalleerd. Nadat een tag naar de container is gepubliceerd, wordt de code gegenereerd en toegevoegd aan de pagina (DOM) van de website. Er wordt geen nieuwe broncode naar de server geïmplementeerd."
        }
      },
      {
      "@type": "Question",
      "name": "Welk probleem lost een TMS op?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "In de loop van de tijd zijn er meer sociale mediaplatforms gekomen met hun eigen tags (LinkedIn, Snapchat, Twitter, Pinterest, Instagram, enz.) en websites zijn overbelast geraakt met hun snippets. Hierdoor zijn de webontwikkelingskosten voor bedrijven gestegen. Tagmanagers lossen deze problemen op omdat implementaties schaalbaar zijn en zo tijd besparen, wat betekent dat bedrijven geld kunnen besparen."
      }
    }
  ]
}
`;

const TagManagement = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Tagbeheer uitgelegd (met ${currentYear} Vergelijking)`}
        description="Leer wat tagbeheersystemen doen, hun voordelen en waarom ze zo populair zijn geworden (inclusief een vergelijking van de huidige TMS-leveranciers)."
        lang="nl"
        canonical="/nl/tagbeheer"
        image="tag-management-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="tag-management/tag-management-hero.png"
          alt="logo's van TMS-platformleveranciers naast elkaar op een donkere achtergrond"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Tagbeheer</H>
        <p className="baseline">
          Tagbeheer geniet van toenemende populariteit en een groeiende gemeenschap. De meeste moderne websites kunnen niet meer zonder een tagbeheersysteem, zodat nu ongeveer <a href="https://w3techs.com/technologies/overview/tag_manager" target="_blank">30% van alle websites een tagmanager gebruikt</a>.
        </p>
        <p>Maar dit is een vrij recente marketingtechnologietrend.</p>
        <p>
          In de afgelopen jaren is er een transformerende nieuwe sector van tagbeheeroplossingen ontstaan. Zelfs tot op de dag van vandaag komen er nieuwe oplossingen op de markt, vanwege veranderende regelgeving en evoluerende marketingtechnologie.
        </p>
        <p className="baseline">
          Een goede illustratie van de groeiende interesse is de wereldwijde zoekopdracht naar "Google Tag Manager" (GTM) - het meest populaire tagbeheersysteem (TMS) met een <a href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet" target="_blank">marktaandeel van 94% gebruiksverdeling op het hele internet</a>:
        </p>
        <ImgScreenshot
          src="tag-management/google-tag-manager-growth-of-popularity-past-10-years.png"
          alt="Grafiek van Google Trends die de toenemende interesse in Google Tag Manager toont"
          caption="De toename in zoekopdrachten naar 'Google Tag Manager' sinds 2010 illustreert de groeiende interesse in tagbeheersystemen. Ironisch genoeg is de algemene term 'tagbeheer' nooit zo populair geworden als de TMS-platformleveranciers."
          className="article-img"
        />
        <p className="baseline">Hoewel de extreme groei al een tijdje aan de gang is, wordt geschat dat het gebruik van tagmanagers <b>zal blijven groeien</b> met een jaarlijkse groei van <a href="https://www.businesswire.com/news/home/20191209005363/en/Global-Tag-Management-System-Market-Analysis-Report" target="_blank">5,7% tot 2024.</a></p>
        <H as="h2">Wat is een tagbeheersysteem?</H>
        <p>
          Een tagbeheersysteem is een JavaScript-bibliotheek om analytische en marketingtags te implementeren en configureren.</p>
        <p>
          HTML-tags, JavaScript-snippets of trackingpixels voegen functies toe aan uw website of app en kunnen met slechts een paar klikken worden geïnstalleerd.
        </p>
        <H as="h3">Voorbeelden van tags</H>
        <ul>
          <li>Google Analytics tracking</li>
          <li>Video tracking</li>
          <li>Retargetingpixels (Facebook, Google Ads, Linkedin etc.)</li>
          <li>Aanmeldformulieren</li>
        </ul>
        <p>
          Tagimplementaties kunnen worden gedaan via een grafische gebruikersinterface (GUI) voor eenvoudige installatie.
        </p>
        <p>Nadat een tag naar de container is gepubliceerd, wordt de code gegenereerd en toegevoegd aan de pagina (DOM) van de website. Er wordt geen nieuwe broncode naar de server geïmplementeerd.</p>
        <p>Het meest populaire TMS is <Link to="/nl/wat-is-google-tag-manager">Google Tag Manager</Link>. Het wordt geleverd met een verscheidenheid aan tagtypen en sjablonen beschikbaar voor derden, zodat u tags kunt implementeren met slechts een paar klikken.</p>
        <p>De implementatie is gebaseerd op 3 componenten:</p>
        <ul>
          <li><strong>Tags</strong> (code die aan de pagina wordt toegevoegd) </li>
          <li><strong>Triggers</strong> (regels met voorwaarden die zeggen wanneer de tag moet worden geactiveerd) </li>
          <li><strong>Variabelen</strong> (dynamische gegevens die worden gebruikt in tags of triggers)</li>
        </ul>

        <ImgScreenshot
          src="generic/google-tag-manager-overview-tab-en.png"
          alt="Google Tag Manager overzichtstabblad"
          className="article-img"
          caption="Overzicht van Google Tag Manager met het paneel aan de linkerkant dat tabbladen toont voor de configuratie van tags, triggers en variabelen."
        />
        <p>De configuratie wordt uitgevoerd met eenvoudige formulieren en invoervelden in plaats van het te moeten coderen. </p>
        <p>Dit vermindert fouten en maakt de implementatie van website-tags <b>toegankelijk voor digitale marketeers zonder programmeerachtergrond.</b></p>

        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-configuration.png"
          alt="Triggerconfiguratie in GTM"
          className="article-img"
          caption="Triggerconfiguratie in GTM. Het definieert wanneer een tag wordt uitgevoerd. Deze wordt uitgevoerd wanneer een HTML-element wordt geklikt dat overeenkomt met de CSS-selector."
        />



        <H as="h2">Welk probleem lost een TMS op?</H>
        <p>
          Met web 2.0 en de verbeterende marketingtechnologie zijn er nieuwe kansen ontstaan voor digitale marketeers. Ze hebben het mogelijk gemaakt om gebruikersgedrag te analyseren voor segmentatie en de gegevens te gebruiken voor advertentiecampagnes.
        </p>
        <p>Om dergelijke gerichte campagnes te kunnen uitvoeren, moesten eerst meerdere <b>tags of pixels</b> op de website worden geplaatst. Daarna kan de trackingpixel bezoekers en hun gedrag categoriseren om hen opnieuw te marketen op Facebook of Google Ads.</p>
        <p>In de loop der tijd hebben meer sociale mediaplatforms hun eigen tags ontwikkeld (LinkedIn, Snapchat, Twitter, Pinterest, Instagram, enz.) en zijn websites overladen geraakt met hun snippets.</p>
        <p>Het werd niet ongebruikelijk om 20 tot 40 afzonderlijke tags in de <code>{`<head>`}</code> sectie van een pagina te zien. </p>
        <p>De basis voor dit tagsysteem is een goed geconfigureerde <Link to="/nl/event-tracking">event tracking setup</Link>. Alle relevante knoppen moeten worden voorzien van JavaScript-functies. Zodra een element wordt aangeklikt, zal de JavaScript de relevante marketingtag op de hoogte stellen.</p>
        <p>De tags vereisten meestal dezelfde gebruikersstatistieken, maar als je een andere dimensie of statistiek wilde toevoegen, werd het snel een omslachtig handmatig taak. Dus het beheer van deze tags alleen al werd een nog groter probleem.</p>
        <p>Ontwikkelaars moesten vele uren besteden aan het plakken van JavaScript-code in HTML-elementen. Weten hoe de code precies moest worden geschreven of welke variabelen konden worden meegezonden, werd een nieuwe organisatorische taak.</p>
        <p>Bedrijven die vaak campagnes wilden uitvoeren, moesten event tracking op elke nieuwe landingspagina instellen om campagne-tracking draaiende te houden. Het onderhouden en up-to-date houden van mastertags, parameters en variabelen werd een extra taak.</p>
        <p>Elke experimentele idee om een nieuwe interactie te volgen, werd een inspanning die moeilijk te rechtvaardigen was. Codewijzigingen werden te arbeidsintensief.</p>
        <p>Kortom, veranderingen en experimenten waren moeilijk en onflexibel. Maar conversietracking moest worden onderhouden om de prestaties van campagnes te meten. Als het niet goed werd gemeten, zou het moeilijk zijn om te verbeteren.</p>
        <p>Als gevolg hiervan stegen de webontwikkelingskosten van bedrijven. Het probleem kon alleen worden opgelost met zelfgeschreven bibliotheken, wat helaas ertoe leidde dat ontwikkelteams het probleem in silo's oplosten en het wiel opnieuw uitvonden.</p>
        <p>De markt was rijp voor innovatie.</p>

        <p>Tagmanagers lossen deze problemen op omdat implementaties schaalbaar kunnen zijn en zo <b>tijd besparen</b>, wat betekent dat bedrijven <b>geld kunnen besparen</b>.</p>
        <p>Ironisch genoeg wordt een tagbeheersysteem ook geïmplementeerd via een <Link to="/nl/wiki-analytics/container-tag">containertag</Link> op alle pagina's. Alle latere tags kunnen echter via de TMS zelf worden toegevoegd.</p>
        <p>De tagmanager-code hoeft maar één keer aan het begin te worden toegevoegd en geïmplementeerd, en alle volgende tags worden geïmplementeerd via de grafische gebruikersinterface van de tagmanager-tool.</p>

        <ImgScreenshot
          src="tag-management/matomo-trigger-types.png"
          alt="Lijst van beschikbare triggertypes in de Matomo Tag Manager tool"
          className="article-img"
          caption="Types of triggers in Matomo. Triggers bepalen wanneer een bepaalde code moet worden uitgevoerd en er is een lange lijst om uit te kiezen - geen programmeervaardigheden vereist."
        />

        <p>De werklast wordt verminderd en de procedure wordt <b>aanzienlijk vereenvoudigd</b>.</p>
        <p>Door de vereenvoudigde implementatie konden <Link to="/nl/google-tag-manager-consultant">tag management consultants</Link> vervolgens de gegevensverzameling van trackingtags configureren. Deze nieuwe rol in de organisatie richt zich uitsluitend op JavaScript-programmering voor webanalyse en digitale marketingdiensten.</p>
        <p>Elke regel en elk gegevenspunt kan nu worden gebruikt in meerdere tags en zelfs op meerdere domeinen.</p>
        <p>Een tagbeheerplatform heeft vele voordelen. Zoveel zelfs dat je zelden klachten hoort.</p>

        <H as="h2">Voordelen</H>
        <p>
          Een van de grote voordelen van tagbeheersystemen is dat een technische marketeer marketingtags kan implementeren zonder afhankelijk te zijn van een ontwikkelaar.
        </p>

        <p>De grafische gebruikersinterface maakt het voor een digitale marketeer gemakkelijk om basisimplementaties uit te voeren die anders te moeilijk zouden zijn in ruwe code.</p>

        <p>Als <b>voorbeeld</b> hier de lijst van marketingtools en analytische tags die je kunt installeren met <b>Matomo Tag Manager</b>:</p>
        <ImgScreenshot
          src="tag-management/matomo-tag-templates.png"
          alt="Lijst van tagtypen in Matomo"
          className="article-img"
          caption="Tagtypelijst van Matomo - Tags worden geleverd met een vooraf gedefinieerd sjabloon, zodat alleen formuliervelden hoeven te worden ingevuld voor implementatie."
        />
        <p>Hieronder zie je een lijst van GTM's beschikbare <b>tagopties</b> (<a href="https://support.google.com/tagmanager/answer/6106924?hl=en" target="_blank" noopener>volledige lijst van ondersteunde tags</a>). Ter vergelijking: GTM heeft de meeste tagsjablonen beschikbaar evenals <a href="https://tagmanager.google.com/gallery/#/?page=1" target="_blank" noopener>aangepaste sjablonen</a>.</p>
        <ImgScreenshot
          src="tag-management/gtm-tag-types.png"
          alt="Lijst van tags van GTM"
          className="article-img"
          caption="Lijst van tags van GTM - Ik moest de taglijst inkorten om ruimte te besparen. Er zijn nog meer tagsjablonen als je onder aangepaste sjablonen kijkt."
        />

        <p className="baseline">Omdat de implementatie van tags in seconden plaatsvindt en een marketingteam niet langer hoeft te wachten op code-implementatie op de server, bespaart het gebruik van een TMS <b>tijd, kosten en vermindert het overheadprojectbeheer</b>.<br />Dit wordt bevestigd in een rapport van Econsultancy uit 2015, waar <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">73% van de marketeers zei dat het gebruik van een TMS helpt kosten te besparen</a>.</p>
        <p>Met deze flexibiliteit zijn agile workflows mogelijk die stapsgewijze campagne-optimalisaties, tests en experimenten mogelijk maken.</p>
        <p>Digitale marketingcampagnes kunnen in meer variëteiten worden uitgevoerd terwijl ze zich richten op specifiekere groepen, wat conversies verhoogt en een goede basis biedt om te leren hoe groepen reageren op aangepaste berichten.</p>
        <p>Bedrijven die al gegevenssilo's beschikbaar hebben, zoals databases, CRM's en API's, kunnen een <Link to="/nl/data-laag">datalaag</Link> gebruiken om de gegevens beschikbaar te maken voor campagnetrackingregels.</p>
        <p>Een ander onderschat voordeel zijn de beschikbare instellingen voor werkruimten, omgevingen en gebruikersrechten die een beheersoplossing vormen om toegang te verlenen aan bureaus of externe consultants. Toegang kan in seconden worden verleend om iemand te laten werken aan een individuele tag of het hele tagplan.</p>

        <p>Samengevat zijn de <b>voordelen van tagbeheer</b> als volgt:</p>
        <ul>
          <li>Kostenbesparingen, dankzij lagere implementatiekosten</li>
          <li>Snelle implementaties</li>
          <li>Verhoogde flexibiliteit aangezien wijzigingen en fixes in seconden kunnen worden doorgevoerd</li>
          <li>Robuuste en stabiele integratie van externe databronnen</li>
          <li>Mogelijkheid om eenvoudig verschillende gebruikersrechten toe te wijzen aan externe partners</li>
        </ul>
        <H as="h3">Nadelen</H>
        <p>Er is een fundamenteel gerechtvaardigde zorg dat de <b>laadsnelheid van een website vertraagt</b> bij het gebruik van een TMS.</p>
        <p>Dit is echter slechts in zeer beperkte mate het geval, vanwege het asynchroon laden van tags.</p>
        <p className="baseline">Websites met een goed geconfigureerde TMS <b>laden meestal sneller</b> dan wanneer tags direct in de broncode zouden zijn geïmplementeerd. Een rapport van Econsultancy uit 2015 stelt dat <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">64% van de TMS-gebruikers snellere websiteprestaties zien</a>.</p>
        <p>Aangezien optimalisatie van laadsnelheid vaak brutaal wordt verwaarloosd en door het minimale snelheidsverlies veroorzaakt door een TMS, zijn er meestal veel effectievere methoden om de laadtijd te verbeteren dan volledig afzien van tagbeheertools.</p>

        <p>En nu, wie zou je denken dat genoeg interesse had in het faciliteren van digitale advertenties en conversietracking om een oplossing gratis te ontwikkelen? Het moet een bedrijf zijn dat profiteert van anderen die digitale advertenties draaien 😉.</p>

        <H as="h2">Platformvergelijking</H>

        <p>Hier is een vergelijking van de belangrijkste platforms op de tagbeheermarkt:</p>

        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Prijs</th>
              <th>Data<br />eigendom</th>
              <th>Beschikbaarheid<br />van Experts</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>GTM (gratis)</b></td>
              <td>gratis</td>
              <td>nee</td>
              <td>hoog</td>
            </tr>
            <tr>
              <td><b>GTM 360</b></td>
              <td>hoog</td>
              <td>ja</td>
              <td>hoog</td>
            </tr>
            <tr>
              <td><b>Adobe Launch</b></td>
              <td>hoog</td>
              <td>ja</td>
              <td>gemiddeld</td>
            </tr>
            <tr>
              <td><b>Tealium iQ</b></td>
              <td>gemiddeld</td>
              <td>ja</td>
              <td>laag</td>
            </tr>
            <tr>
              <td><b>Matomo</b></td>
              <td>laag</td>
              <td>ja</td>
              <td>laag</td>
            </tr>
            <tr>
              <td><b>Piwik</b></td>
              <td>gemiddeld</td>
              <td>ja</td>
              <td>laag</td>
            </tr>
          </tbody>
        </table>
        <p>Voor ondernemingen valt de keuze meestal op premium platforms, zoals <b>Google Tag Manager 360, Adobe Launch</b> (de opvolger van Adobe DTM) of <b>Tealium iQ</b>, omdat ze voldoen aan de privacyvereisten van bedrijven voor klantgegevens.</p>
        <p>
          De prijs van een premium TMS van Google 360, Adobe of Tealium kan niet precies worden gedefinieerd, omdat ze worden verkocht in configureerbare enterprise pakketten samen met andere analysetools.
        </p>

        <p>
          <u>De prijzen zijn daarom slechts bij benadering en moeten als een ruwe oriëntatie worden gezien</u>. Individuele prijzen kunnen variëren.
        </p>

        <p>Als je wilt zien hoe <b>Tealium Tag Manager</b> eruitziet, bekijk dan hun demovideo:</p>
        <YouTube id="HF1ZiPQYP6M" />
        <br />
        <p>Evenzo is dit een video waarin je de <b>Adobe Launch</b> interface kunt zien en hoe een paginalaadregel daarin wordt ingesteld:</p>
        <YouTube id="JR2mRfKU18Y" />
        <br /><br />
        <p>De meest populaire tagbeheer tool is echter de <a href="https://tagmanager.google.com/" target="_blank">gratis versie van Google Tag Manager</a>.</p>
        <H as="h2">Veelvoorkomende gebruiksscenario's</H>

        <p>Gegevensverzameling voor webanalyse, digitale marketing of datawetenschappelijk onderzoek zijn de meest voorkomende toepassingen voor tagbeheersystemen.</p>


        <H as="h3">Webanalyse</H>
        <p>Webanalysesystemen zoals Google Analytics zijn snel op te zetten en gemakkelijker te schalen over meerdere domeinen met behulp van een TMS. Eventuele latere toevoegingen of wijzigingen worden snel geïmplementeerd en vereisen minder middelen, wat de reden is waarom tagbeheer de afgelopen jaren een standaard is geworden voor digitale analyse.</p>
        <p><b>Conversieoptimalisatie (CRO)</b> is ook grotendeels gebaseerd op statistieken van een analytische tag en is daarom sterk gekoppeld.</p>

        <H as="h3">Digitale Marketing</H>
        <p>Advertenties op platforms zoals Facebook, Twitter, Instagram en Google Ads zijn populaire kanalen voor verkeerswerving. Digitale advertenties via retargeting met banners of Adwords zijn daarom het meest voorkomende gebruiksscenario.</p>
        <p>Dergelijke marketingtechnologie is gebaseerd op tags die datapunten van één of meer digitale eigendommen vereisen om gebruikerssegmenten te creëren. Daarom is het beheren van tags op een controleerbare, schaalbare manier een vereiste.</p>
        <p><b>Affiliate marketing</b> is gebaseerd op het toewijzen van conversies aan verkeersverwijzingen en is ook sterk afhankelijk van cookie-logica die wordt geïmplementeerd via tagmanagers.</p>

        <H as="h3">Data Science</H>
        <p>Met de opkomst van AI is de behoefte aan gegevens toegenomen, aangezien datamodellen moeten worden getraind met enorme hoeveelheden gegevens.</p>
        <p>Gegevens die niet kunnen worden geschraapt van statische pagina's, worden meestal verzameld met een tagbeheeroplossing. Van daaruit wordt het verder gestuurd naar een gegevensopslag die toegankelijk is voor datamodellering.</p>

        <H as="h2">Tagbeheer Consulting</H>
        <p>Consultancydiensten voor tagbeheer verschenen gelijktijdig met de groei van de markt: Extra aanbieders verschenen met nieuwe oplossingen en prijspunten die in overweging moesten worden genomen.</p>
        <p>Ook <b>de wetgeving inzake gegevensbescherming van gebruikers in de EU</b> (AVG) veranderde de vereisten voor configuraties en gaf privacygerichte aanbieders een duwtje in de rug.</p>
        <p>Consultancy voor tagbeheer richt zich op platformselectie, implementatie, validatie en onderhoud van het systeem.</p>
        <p>Tagbeheerconsultants worden meestal ingehuurd van bureaus die zich richten op digitale advertenties, webanalyse, of ze zijn <Link to="/nl/google-tag-manager-consultant">freelance consultants</Link>.</p>

        <H as="h2">Zijn er gevallen waarin een TMS niet de juiste oplossing is?</H>
        <p><b>Een tagbeheersysteem is de juiste keuze voor de meeste websites en apps</b>. Alleen websites die actief zijn in sectoren met specifieke vereisten op het gebied van gegevensbescherming, moeten goed kijken naar hoe de infrastructuur is ontworpen om te voldoen aan de wetgeving inzake gegevensbescherming.</p>
        <p>Dit is echter meestal geen geval tegen het gebruik van een TMS, maar eerder een pleidooi voor het kiezen van de juiste.</p>
        <p>Een andere overweging kan de prestaties van de site zijn.</p>
        <p>Als een TMS niet correct is ingesteld, kan het een website vertragen. Nichemarkten waar advertenties een belangrijke rol spelen, kunnen problemen ondervinden met trage laadtijden van pagina's. Het besparen van enkele honderden milliseconden kan waarde toevoegen, maar het is de vraag of de voordelen van een snellere laadtijd de hogere kosten voor het beheer van trackingpixels rechtvaardigen.</p>
        <p>Afhankelijk van de capaciteiten van interne middelen is het mogelijk om een website en digitale marketing succesvol te runnen zonder een TMS, maar dit zijn meestal uitzonderingsgevallen.</p>
        <p>Elke website die niet een extreem aantal pagina's heeft of nichevereisten voor externe tags, zou hun HTML- en JavaScript-tags prima kunnen beheren met een TMS.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default TagManagement;
